.modal {
    @apply fixed bottom-0 items-center left-0 top-0 z-40 flex h-dvh w-full flex-col overflow-y-auto bg-black/90 md:py-4 opacity-0 backdrop-blur-sm transition duration-200 lg:py-16 lg:px-16;

    .modal__container {
        @apply relative flex translate-y-10 transform-gpu flex-col space-y-4 md:rounded-md bg-white py-8 shadow transition duration-200 focus:outline-none focus:ring-4 focus:ring-akashic-black-900 sm:block sm:flex-none w-full md:w-auto xl:max-w-184 flex-1;

        &.--padding-horizontal {
            @apply px-4 md:px-8;
        }

        .modal__close-button {
            @apply absolute right-3 top-3 flex h-10 w-10 items-center justify-center rounded-full bg-slate-50 focus:outline-none focus:ring-2 focus:ring-akashic-white-900;
        }

        .modal__header {
            @apply relative flex justify-center items-center space-x-4;

            .modal__title {
                @apply flex-1;
            }

            .modal__title-text {
                @apply text-center text-xl font-bold tracking-tight;
            }
        }

        .modal__body {
            @apply flex flex-1 justify-center;
        }
    }

    &.modal--center {
        @apply sm:justify-center;
    }

    &.modal--ready {
        @apply opacity-100;

        .modal__container {
            @apply translate-y-0;
        }
    }

    &.modal--no-header {
        .modal__header {
            @apply justify-center;
        }
    }

    &.modal--has-markdown-content {
        .modal__container {
            .modal__body {
                @apply whitespace-break-spaces;

                a {
                    @apply font-medium underline underline-offset-1 text-cerulean-700;
                }
            }
        }
    }
}
