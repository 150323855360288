.__image-carousel {
    @apply w-full;

    .__image-container {
        @apply relative opacity-0 transition-opacity duration-300;

        &.--loaded {
            @apply opacity-100;
        }

        .__controls {
            @apply absolute top-0 left-0 w-full h-full flex items-center;

            .__previous,
            .__next {
                @apply flex-1;

                button {
                    @apply drop-shadow shadow bg-white;
                }
            }

            .__previous {
                @apply ml-2 md:ml-4;
            }

            .__next {
                @apply text-right mr-2 md:mr-4;
            }
        }

        .__image {
            @apply w-full md:w-144 lg:w-184 lg:max-h-104 2xl:max-h-192 xl:w-192 md:rounded overflow-hidden object-cover;
        }
    }

    .__figure {
        @apply space-y-4 md:px-8;

        .__figure-caption {
            @apply text-sm text-slate-500 px-4 md:px-0;
        }
    }
}
