.notice {
    @apply relative rounded-md border-b-4 p-4 w-full pr-11;

    .notice__title {
        @apply font-bold;
    }

    .notice__message {
        @apply text-sm font-medium;
    }

    .notice__dismiss {
        @apply absolute top-1.5 right-1.5;

        button {
            @apply w-7 h-7;
        }
    }

    &.notice--type-error {
        @apply border-red-700 bg-red-100;

        .notice__title,
        .notice__message {
            @apply text-red-700;
        }
    }

    &.notice--type-success {
        @apply border-green-700 bg-green-100;

        .notice__title,
        .notice__message {
            @apply text-green-700;
        }
    }

    &.notice--type-warning {
        @apply border-yellow-700 bg-yellow-100;

        .notice__title,
        .notice__message {
            @apply text-yellow-700;
        }
    }

    &.notice--wrap {
        @apply w-auto inline-block;
    }
}
