.footer {
    @apply relative z-20 w-full space-y-8 border-t border-cerulean-900 bg-stratos-950 py-8 lg:py-16 2xl:py-20;

    .__row-cols {
        @apply flex flex-col lg:flex-row lg:space-x-8 xl:space-x-32 lg:pb-12;

        .__main-row {
            @apply lg:flex lg:items-center lg:space-x-8 lg:space-y-0;

            .brand {
                @apply flex-1 pb-24 pt-16 text-center lg:py-0 lg:text-left;

                .logo {
                    @apply inline-flex items-center justify-center w-48 h-36 sm:w-40 sm:h-28 lg:w-52 lg:h-36;
                }
            }
        }

        .__links-row {
            @apply hidden lg:block lg:flex-1;
        }

        .__top-row,
        .__mid-row {
            @apply grid grid-cols-2 gap-4 lg:flex lg:gap-8 xl:gap-16;
        }

        .__top-row {
            @apply border-b border-b-cerulean-950 pb-8 lg:grid-cols-4;
        }

        .__mid-row {
            @apply pt-8;
        }

        .__disclosure-row {
            @apply lg:hidden;

            .__divider {
                @apply bg-cerulean-950 w-full h-px my-8;
            }

            h4 {
                @apply hidden;
            }

            ul {
                li {
                    @apply border-t border-t-cerulean-900;

                    a {
                        @apply w-full block py-2;
                    }
                }
            }
        }

        .__col {
            @apply relative flex flex-col justify-start space-y-0.5 md:flex-1 md:space-y-1;

            .__col-header {
                @apply text-sm font-bold uppercase tracking-tight text-slate-400;
            }

            .__col-links {
                .__col-link {
                    @apply text-xs font-medium text-bright-turquoise-500 underline underline-offset-2 hover:text-bright-turquoise-600;
                }
            }
        }
    }

    .__bottom-row {
        @apply border-t border-t-cerulean-950 pt-8 text-center md:text-left;

        .__text {
            @apply text-xs font-medium text-slate-400;
        }
    }

    &.--authenticated {
        .__row-cols {
            @apply flex-col lg:flex-row lg:space-x-32 lg:pb-12;

            .__main-row {
                @apply py-0 lg:border-none;
            }

            .__links-row {
                @apply lg:flex-1 lg:pt-0;
            }
        }
    }
}
