.share__input {
    @apply absolute -top-full;
}

.share__copy-message {
    @apply text-slate-200 text-xs sm:text-sm font-medium sm:pl-2 opacity-0 duration-200;

    &.--visible {
        @apply opacity-100;
    }
}

.share-modal__content {
    @apply w-full;

    .share-modal__items {
        @apply space-y-1;
    }

    .share__copy-message {
        @apply text-slate-600 font-semibold px-0 py-2 block;
    }
}
