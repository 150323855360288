.festival_tiles {
    @apply h-full w-full justify-start overflow-hidden;

    .__tile {
        @apply relative flex flex-col transition duration-200 overflow-hidden bg-black;

        .__content {
            .__img_container {
                @apply h-full w-full overflow-hidden;

                img {
                    @apply h-full w-full origin-center transform-gpu object-cover transition duration-500;
                }
            }

            .__meta {
                @apply w-full;

                .__text {
                    @apply flex h-full w-full flex-col items-start py-3 md:bg-slate-950 md:p-2 lg:p-4 overflow-hidden;

                    .__festival_name {
                        @apply font-roboto text-left font-black w-full overflow-hidden;

                        .__label {
                            @apply tracking-tight text-white md:uppercase lg:text-xl 2xl:text-2xl truncate;
                        }
                    }

                    .__festival_venue {
                        @apply font-montserrat text-left text-sm font-medium text-slate-100 md:text-xs md:font-semibold lg:text-sm xl:text-sm whitespace-break-spaces;
                    }

                    .__festival_date {
                        @apply font-roboto text-left text-sm font-medium tracking-tight text-slate-400 sm:py-1 md:text-xs md:font-semibold lg:text-sm;
                    }

                    .__festival_tags {
                        @apply w-full flex-none h-8;

                        .__festival_tag {
                            @apply mr-1;
                        }
                    }
                }

                .__gradient {
                    @apply absolute left-0 top-0 z-10 hidden h-full w-full bg-gradient-to-t from-slate-950/70 to-transparent transition duration-500 sm:block;
                }
            }
        }
    }

    &.--row {
        .__row_container {
            @apply flex flex-wrap overflow-hidden bg-black;

            .__tile {
                @apply h-48 w-1/4 flex-none overflow-hidden lg:w-4/24 bg-slate-950;

                img {
                    @apply h-full w-full object-cover;
                }
            }
        }
    }

    &.--masonry {
        .__masonry_container {
            @apply flex space-x-2 overflow-x-auto pb-2 lg:flex-wrap lg:space-x-0 lg:border lg:border-black lg:rounded-md lg:p-0;

            .__tile {
                @apply w-48 flex-none rounded-md bg-slate-950 h-80 sm:h-[292px] sm:w-1/2 lg:h-64 lg:w-1/4 lg:rounded-none lg:p-0 xl:h-72;

                &:first-child {
                    @apply sm:z-20 lg:h-[512px] lg:w-1/2 xl:h-120;
                }

                &:nth-child(4) {
                    @apply lg:ml-[50%];
                }

                &:nth-child(4),
                &:nth-child(5) {
                    @apply lg:-mt-64 xl:-mt-72;
                }

                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                    @apply lg:h-80 lg:w-1/4 xl:h-96;
                }

                .__content {
                    @apply p-2 lg:p-0 flex flex-col h-full overflow-hidden rounded-md;

                    .__img_container {
                        @apply aspect-square h-48 rounded lg:aspect-auto lg:h-auto lg:flex-1 lg:rounded-none;

                        img {
                            @apply h-full w-full object-cover;
                        }
                    }
                }

                &.--loading {
                    @apply p-0.5;
                }

                &:hover {
                    .__content {
                        .__meta {
                            .__gradient {
                                @apply opacity-0;
                            }
                        }

                        img {
                            @apply scale-105;
                        }
                    }
                }
            }
        }
    }
}
