.download-banner {
    @apply relative z-20 mt-8 flex w-full items-start overflow-hidden py-16 md:mt-16 lg:mt-32 lg:py-24;

    .__content {
        @apply relative z-30 flex w-full md:space-x-8 2xl:space-x-16;

        .__left-col {
            @apply space-y-16 md:flex-1 lg:space-y-20;

            .__masthead {
                @apply space-y-4 lg:w-2/3;

                .__header {
                    @apply text-3xl font-bold tracking-tighter text-slate-50 lg:text-5xl;
                }

                .__text {
                    @apply text-sm text-slate-300;
                }
            }

            .__store_buttons {
                @apply flex w-full space-x-2 lg:w-auto lg:space-x-4;

                .__container {
                    @apply flex items-center;

                    .__button {
                        @apply relative inline-flex h-12;

                        .__google {
                            @apply relative h-12 w-[162px];
                        }

                        .__apple {
                            @apply relative h-12 w-36;
                        }
                    }
                }
            }

            .features {
                @apply mt-10 space-y-8 md:flex md:space-x-8 md:space-y-0 lg:mt-16 lg:space-x-8 2xl:space-x-16;

                .__disclosures {
                    .__body {
                        @apply text-slate-400 py-4;
                    }
                }

                .__feature {
                    @apply flex flex-col items-center space-y-2 md:block md:flex-1;

                    .__icon {
                        @apply flex h-10 w-10 items-center justify-center rounded-full bg-cerulean-500 text-white;
                    }

                    .__title {
                        @apply text-lg font-medium text-slate-100;
                    }

                    .__body {
                        @apply text-center text-sm text-slate-400 md:text-left;
                    }
                }
            }
        }

        .__right-col {
            @apply hidden lg:block;

            .__device-mock {
                @apply h-104 w-auto;
            }
        }
    }

    .__tiles {
        @apply absolute left-0 top-0 z-0;
    }

    .__gradient {
        @apply absolute left-0 top-0 z-10 h-full w-full bg-black md:bg-transparent md:bg-gradient-to-l md:from-stratos-950/90 md:via-black md:to-black;
    }
}
