.disclosure {
    @apply border-b border-b-cerulean-500;

    .__trigger {
        button {
            @apply px-0;
        }

        .__heading-container {
            @apply flex items-center flex-1 space-x-4;

            .__text {
                @apply flex-1;

                .__heading {
                    @apply tracking-tight flex items-center text-slate-50 font-medium text-lg flex-1 text-left;
                }
            }

            .__icon {
                @apply flex items-center last:text-slate-300;

                &.--left {
                    @apply bg-cerulean-500 rounded-full text-white flex-none p-2;
                }
            }
        }
    }

    &.--has-pageheader {
        .__trigger {
            @apply pb-4;
        }
    }

    &.--expanded {
        .__content {
            @apply block;
        }
    }

    &.--collapsed {
        .__content {
            @apply hidden;
        }
    }
}
